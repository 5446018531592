@import './../theme/colors.scss';
body {
  .dropdown-content li>span {
      color: var(--primaryColor);
  }
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //spinner start
  .container-ring{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 997;
    .lds-ring {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
      left: 48.5%;
      top: calc(50% - 80px);
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid var(--secondaryColor);
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: var(--secondaryColor) transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  //spinner end

  //colors for materialize buttons
  a.modal-close.waves-effect.waves-green.btn-flat.btn.btn-success:hover {
    background-color: var(--secondaryColor);
  }
  .dropdown-trigger.btn {
    background-color: var(--secondaryColor);
  }
  .dropdown-trigger.btn:hover {
    background-color: var(--alternateColor);
  }
  .btn-small {
    background-color: var(--secondaryColor);
  }
  .btn-small:hover {
    background-color: var(--alternateColor);
  }
  [type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after {
    border: 2px solid var(--secondaryColor);
  }
  .datepicker-date-display, .timepicker-digital-display {
    background-color: var(--secondaryColor);
  }
  .timepicker-container  .timepicker-canvas-bg, .timepicker-container  .timepicker-canvas-bearing{
    fill: var(--secondaryColor);
  }
  .timepicker-container .timepicker-canvas line{
    stroke: var(--secondaryColor);
  }
  .datepicker-cancel, .datepicker-clear, .datepicker-today, .datepicker-done, .timepicker-container .timepicker-close {
    color: var(--secondaryColor);
  }
  .btn-floating.btn-large {
    background-color: var(--secondaryColor);
  }
  .btn-floating.btn-large:hover {
    background-color: var(--alternateColor);
  }
  #editor-template-side-nav-editor{
    width: 50%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  main {
    flex: 1 0 auto;
  }
}
// states
input.error {
  border-bottom: 3px solid red !important;
}
.highLight{
  border: 1px solid;
  padding: 9px;
}

.message-blue {
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #A8DDFD;
    width: 200px;
    // height: 50px;
    padding: 10px 10px 20px 10px;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid #97C6E3;
    border-radius: 10px;
}

.message-orange {
    position: relative;
    margin-bottom: 10px;
    margin-left: calc(100% - 240px);
    padding: 10px;
    background-color: #f8e896;
    width: 200px;
    // height: 50px;
    padding: 10px 10px 16px 10px;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid #dfd087;
    border-radius: 10px;
}

.message-content {
    padding: 0;
    margin: 0;
    word-break: break-all;
}

.message-timestamp-right {
    position: absolute;
    font-size: .85em;
    font-weight: 300;
    bottom: 5px;
    right: 5px;
}

.message-timestamp-left {
    position: absolute;
    font-size: .85em;
    font-weight: 300;
    bottom: 5px;
    left: 5px;
}

.message-blue:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #A8DDFD;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -15px;
}

.message-blue:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #97C6E3;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    left: -17px;
}

.message-orange:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 15px solid #f8e896;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: 0;
    right: -15px;
}

.message-orange:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 17px solid #dfd087;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    bottom: -1px;
    right: -17px;
}
.nice-effect{
  transform: translate(0, -50px);
  -webkit-transform: translate(0,-50px);
  -o-transform: translate(0, -50px);
  -moz-transform: translate(0, -50px);
  zoom: 1;
  filter: alpha(opacity = 50);
  opacity: 1 !important;
  -webkit-transition:  opacity all 2s ease -in -out;
  -moz-transition:  opacity all 2s ease -in -out;
  -ms-transition:  opacity all 2s ease -in -out;
  -o-transition:  opacity all 2s ease -in -out;
  transition:  opacity all 2s ease -in -out;
}
