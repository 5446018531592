header{
  .nav-wrapper{
    .sidenav-trigger{
      display: block;
      cursor: pointer;
    }
  }
  .nav-extended {
    position: fixed;
    z-index: 500;
  }
}
// agrandan al maximo el container principal
.main-container-layout{
    padding-top: 65px;
    display: flex;
    flex-wrap: wrap;
    //background-color: #27343B;
    .container{
        // background-color: whitesmoke;
        width: 90%;
        max-width: 100%;
    }
}
