#directo-report-component{
    .row:first-child{
      .input-field.col.s12.m6.l4:first-child{
        cursor: pointer;
      }
      .input-field.col.s12.m6.l4:last-child{
        text-align: center;
        .btn-floating.btn-large.waves-effect.waves-light{
          cursor: pointer;
        }
      }
    }
}
