.header-layout{
  #slide-out {
    background-color: var(--secondaryColor);
    li{
      .text-view{
        //color: white;
        mix-blend-mode: difference
      }
      a{
        color: var(--baseColor);
        text-transform: uppercase;
      }
      a.active {
        background-color: rgba(0, 0, 0, 0.15);
      }
      a.logout {
        position: absolute;
        right: 0;
        top: -8px;
        z-index: 2;
        margin: 0;
        padding: 0;
        width: 24px;
        height: 38px;
        span.material-icons{
          cursor:pointer;
          height: 38px;
        }
      }
      .user-view{
        .background{
          img{
            width: 100%
          }
        }
        a{
          span {
          }
        }
      }
    }
    .collapsible-header {
      padding: 0 32px;
    }
    .collapsible-body{
      background-color: var(--secondaryColor);
      a{
        padding: 0 42px;
      }
    }
  }

}
