.header-layout{
  nav{
    background-color: var(--primaryColor);
    color: #d3d4d4;
    #nav-mobile{
      position: absolute;
      right: 0;
    }
  }
  // #slide-out {
  //   background-color: #061F2F;
  //   li{
  //     .text-view{
  //       //color: white;
  //       mix-blend-mode: difference
  //     }
  //     a{
  //       color: #d3d4d4;
  //     }
  //     a.logout {
  //       position: absolute;
  //       right: 0;
  //       top: -8px;
  //       z-index: 2;
  //       margin: 0;
  //       padding: 0;
  //       width: 24px;
  //       height: 38px;
  //       span.material-icons{
  //         cursor:pointer;
  //         height: 38px;
  //       }
  //     }
  //     .user-view{
  //       .background{
  //         img{
  //           width: 100%
  //         }
  //       }
  //       a{
  //         span {
  //         }
  //       }
  //     }
  //   }
  // }

}
