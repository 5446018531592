#home{
  .clock{
    font-family: monospace;
    background-color: var(--secondaryColor);
    color: var(--baseColor);
    padding: 0 10px;
  }
  .select-container {
    margin-top: 2em;
  }
  #home-message-component{
    #import-campaign{
      margin: 0px;
    }
  }
  #home-rcs-component{
    .col.s12{
      .card-panel{
        height: 160px;
        text-align: center;
        color: var(--baseColor);
        h2{
          margin: 0;
        }
        p{
          font-size:25px;
          margin: 0;
        }
      }
    }
    .col.s12.m3{
      .card-panel{
        background-color: var(--secondaryColor);
      }
    }
    .col.s12.m9{
      .card-panel{
        background-color: var(--primaryColor);
        .information{
          p:last-child {
            font-size: 10px
          }
        }
        .verify{
          display: flex;
          flex-direction: column;
          #telephone{
            color: var(--baseColor);
            border-bottom: 1px solid var(--baseColor);
            font-size: 2em;
            text-align: center;
          }
          #telephone:focus{
            border-bottom: 1px solid var(--secondaryColor);
            box-shadow: 0 1px 0 0 var(--secondaryColor);
          }
          i.active
          ,label.active{
            // border-bottom: 1px solid var(--secondaryColor);
            color:var(--secondaryColor);
          }
          .btn{
            display: flex;
            margin: 0 auto;
            align-items: center;
            text-align: center;
            background-color: var(--secondaryColor);
            color: var(--baseColor);
            i{
              padding: 0 5px 0 0;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 601px) {
    .select-container{
      width: 100%;
    }
    #home-rcs-component{
      .col.s12.m9{
        .card-panel{
          display: flex;
          flex-direction: column;
          height: auto;
          .col.s6{
            width: 100%
          }
        }
      }
    }

    #home-message-component{
      #import-campaign{
        margin: 15px 0px;
      }
      .row:first-child {
        .col.s4, .col.s8 {
          width: 100%;
        }
      }
    }
  }
}
