#files-select-container{
  margin-right: 4em;

  .dropdown-content {
    width: 155px !important;
  }

  .disabled {
    pointer-events: none;

    a.disabled {
      color: red
    }

    a.ready {
      color: green;
    }
  }
}