  .graphic-zone{
    // background: gray;
    // width: 100%;
    padding: 1em 0 1em 0;
    height: 100%;

    .graphic{
      // border: dotted;
      width: 100%;
      height: 450px;
      padding: .5em;
      .chartjs-render-monitor{
        max-height: 90%
      }
      label.title{
        font-weight: bold;
        display: block;
        font-size: 1em;
        color:  var(--secondaryColor)
      }
      label.title::first-letter{
        text-transform: capitalize;
      }
    }
  }
  .no-graphic-zone {
    height: 450px;
    .no-graphic {
      background-color: whitesmoke;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }
