@import '../../../../theme/function.scss';

#email-report{
  .clock{
    font-family: monospace;
    background-color: var(--secondaryColor);
    color: var(--baseColor);
    padding: 0 10px;
  }
  .select-container {
    margin-top: 2em;
  }
  .datepicker-container {
    margin-top: 2em;
  }
  div.deviceInfo {
    padding-right: 2em;

    div.col.s6.m2 {
      padding: 30px 0;
    }
  }
  div.linkClicks {
    padding-left: 2em;

    div.col.s6.m2 {
      padding: 30px 0;
    }
  }
  div.unsubscribe {
    padding-right: 2em;

    div.col.s6.m2 {
      padding: 30px;
    }
  }
  #card-component {
    div.card-panel {
      background-color: var(--secondaryColor);
      color: var(--baseColor);
      padding: 20px 0px;

      .count {
        font-size: 8vw;

        @media screen {
          font-size: 4vh
        }
      }
    }
    @media only screen and (min-width: 899px) {
      div.l1 {
        width: 12.5%;

        div.card-panel {
          height: 110px;

          span {
            font-size: 1vw;
          }

          center:first-of-type {
            font-size: 1.3em;
          }
        }
      }
    }
  }
  #unsubscribes{
    .col.s12{
      .card-panel{
        height: 160px;
        text-align: center;
        color: var(--baseColor);
        h2{
          margin: 0;
        }
        p{
          font-size:25px;
          margin: 0;
        }
      }
    }
    .col.s12.m3{
      .card-panel{
        background-color: var(--secondaryColor);
      }
    }
    .col.s12.m9{
      .card-panel{
        background-color: var(--primaryColor);
        .information{
          p:last-child {
            font-size: 10px
          }
        }
        .verify{
          display: flex;
          flex-direction: column;
          #telephone{
            color: var(--baseColor);
            border-bottom: 1px solid var(--baseColor);
            font-size: 2em;
            text-align: center;
          }
          #telephone:focus{
            border-bottom: 1px solid var(--secondaryColor);
            box-shadow: 0 1px 0 0 var(--secondaryColor);
          }
          i.active
          ,label.active{
            // border-bottom: 1px solid var(--secondaryColor);
            color:var(--secondaryColor);
          }
          .btn{
            display: flex;
            margin: 0 auto;
            align-items: center;
            text-align: center;
            background-color: var(--secondaryColor);
            color: var(--baseColor);
            i{
              padding: 0 5px 0 0;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 601px) {
    .select-container{
      width: 100%;
    }
    #unsubscribes{
      .col.s12.m9{
        .card-panel{
          display: flex;
          flex-direction: column;
          height: auto;
          .col.s6{
            width: 100%
          }
        }
      }
    }
  }
}
