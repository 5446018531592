@import '../../../../theme/function.scss';

#email-report{
  .clock{
    font-family: monospace;
    background-color: var(--secondaryColor);
    color: var(--baseColor);
    padding: 0 10px;
  }
  .select-container {
    margin-top: 2em;
  }
  .datepicker-container {
    margin-top: 2em;
  }
  .searchField-container {
    margin-top: 2em;
  }
  .buttons-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    a {
      margin-right: 1em;
    }

    @media only screen and (max-width: 601px) {
      justify-content: center;
      margin-bottom: 2em;
    }
  }
  // div.row > div.row > div.col {
  //   overflow-x: auto;
  // }
  div.col.s6.offset-s6.m3.offset-m9.l2.offset-l10 {
    margin-bottom: inherit;
  }
  div.deviceInfo {
    padding-right: 2em;
  }
  div.linkClicks {
    padding-left: 2em;
  }
  @media only screen and (max-width: 601px) {
    .select-container{
      width: 100%;
    }
    .buttons-row {
      justify-content: center;
      margin-bottom: 2em;
    }
  }
  @media only screen and (min-width: 992px) {
    table.responsive-table {
      font-size: 0.9vw;
    }
  }
}
