#login-container{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  // background-color: var(--primaryColor);
  main {
    background-color: var(--baseColor);
    flex: 1 0 auto;
    center{
      padding-top: 10%;
      img.responsive-img{
        width: 250px;
        margin-bottom: 50px;
      }
      a{
        color: #ffffff
      }
      .container {
        .form-container-login {
          width: 400px;
          background-color: var(--primaryColor);
          form{
            .input-field.col.s12{
              input{
                color:#ffffff;
              }
              input:not(.browser-default):focus{
                  border-bottom: 1px solid var(--baseColor);
                  box-shadow: 0 0 0 0 var(--baseColor);
                }
              label{
                color: var(--baseColor);
              }
            }
            .col.s12.btn.btn-large.waves-effect{
              background-color: var(--primaryColor);
            }
          }
        }
      }
    }
  }
}
